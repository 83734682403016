import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator, StackHeaderProps } from "@react-navigation/stack";
import { Appbar, Provider as PaperProvider, DefaultTheme } from "react-native-paper";
import HomeScreen from "./screens/HomeScreen";
import { list } from "./lists";
import ListScreen from "./screens/ListScreen";

const Stack = createStackNavigator();

export type RootStackParamList = {
	Home: undefined;
	List: { list: list };
};

const theme = {
	...DefaultTheme,
	roundness: 2,
	colors: {
		...DefaultTheme.colors,
		primary: "#cc0000",
	}
};


function CustomNavigationBar(props: StackHeaderProps) {
	return (
		<Appbar.Header>
			{props.previous && <Appbar.BackAction onPress={props.navigation.goBack} />}
			<Appbar.Content titleStyle={{ fontSize: 20 }} title={props.scene.descriptor.options.headerTitle || props.scene.route.name} />
		</Appbar.Header>
	);
}

function App(): JSX.Element {
	return (
		<PaperProvider theme={theme}>
			<NavigationContainer>
				<Stack.Navigator screenOptions={{
					header: (props) => <CustomNavigationBar {...props} />,
				}}>
					<Stack.Screen name="OneSixtyOne" component={HomeScreen} />
					<Stack.Screen name="List" component={ListScreen} />
				</Stack.Navigator>
			</NavigationContainer>
		</PaperProvider>
	);
}

export default App;