import { useTheme } from "@react-navigation/native";
import * as React from "react";
import { List, Checkbox } from "react-native-paper";
import { listItem } from "../lists";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface listItemProps {
	listKey: string
	item: listItem
}

export default function ListItem(props: listItemProps): JSX.Element {
	const [isDone, setIsDone] = React.useState(false);

	const storageKey = `${props.listKey}:${props.item.number}`;

	const setDone = async (doneState: boolean) => {
		await AsyncStorage.setItem(storageKey, doneState ? "true" : "false");
		setIsDone(doneState);
	};

	React.useEffect(() => {
		const getData = async () => {
			try {
				const data = await AsyncStorage.getItem(storageKey);
				setIsDone(data == "true");
			} catch (e) {
				setIsDone(false);
			}
		};
		getData();
	}, [props.item.number, props.listKey, storageKey]);

	const { colors } = useTheme();

	return <List.Item
		titleNumberOfLines={0}
		title={props.item.item}
		titleStyle={{ textDecorationLine: isDone ? "line-through" : "none" }}
		description={`#${props.item.number}`}
		left={props => <Checkbox {...props} status={isDone ? "checked" : "unchecked"} color={colors.primary} uncheckedColor={colors.primary} />}
		onPress={() => setDone(!isDone)}
	/>;
}