import * as React from "react";
import { Card } from "react-native-paper";
import { list } from "../lists";

interface listCardProps {
	list: list
	onPress(): void
}

export default function ListCard(props: listCardProps): JSX.Element {
	return <Card mode={"elevated"} onPress={props.onPress}>
		<Card.Title titleNumberOfLines={0} title={props.list.title} subtitle={props.list.author} />
	</Card>;
}