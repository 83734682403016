import { StackNavigationProp } from "@react-navigation/stack";
import * as React from "react";
import { View } from "react-native";
import { RootStackParamList } from "../App";
import ListCard from "../components/ListCard";

import lists from "../lists.json";

type HomeScreenNavigationprop = StackNavigationProp<RootStackParamList>;

type Props = {
	navigation: HomeScreenNavigationprop;
};
export default function HomeScreen(props: Props): JSX.Element {
	return <View>
		{lists.map((list, i) => <ListCard list={list} key={i} onPress={() => props.navigation.push("List", { list: list })} />)}
	</View>;
}