import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import * as React from "react";
import { ScrollView, FlatList } from "react-native";
import { Divider, List, useTheme } from "react-native-paper";
import { RootStackParamList } from "../App";
import ListItem from "../components/ListItem";
import { listItem } from "../lists";
type ListScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type ListScreenRouteProp = RouteProp<RootStackParamList, "List">

type Props = {
	navigation: ListScreenNavigationProp;
	route: ListScreenRouteProp
}
export default function ListScreen(props: Props): JSX.Element {
	const list = props.route.params.list;

	React.useLayoutEffect(() => props.navigation.setOptions({ headerTitle: list.title }), [list.title, props.navigation]);

	const renderItem = ({ item }: { item: listItem }) => <ListItem item={item} listKey={list.key} />;

	return <FlatList
		ItemSeparatorComponent={Divider}
		renderItem={renderItem}
		data={props.route.params.list.items}
		keyExtractor={(item) => `${item.number}`}
	/>;
}

export const navigationItems = {
	title: "list",
};